export const netWorthMock = {
  parts: [
    {
      id: "b4e19420-851c-4e44-b40d-09fec99eb231",
      name: "Gotówka",
      type: "asset",
      isVisible: true,
      order: 1,
      currency: "PLN",
    },
    {
      id: "52c952c2-0cff-4cb9-bbe6-887ed052ee39",
      name: "Inwestycje",
      type: "asset",
      isVisible: true,
      order: 2,
      currency: "PLN",
    },
    {
      id: "9f28846b-f34d-4766-8a72-383254c54751",
      name: "Samochód",
      type: "asset",
      isVisible: true,
      order: 3,
      currency: "PLN",
    },
    {
      id: "82ce2911-5b82-491c-a75c-af82cdf3abe5",
      name: "Mieszkanie",
      type: "asset",
      isVisible: true,
      order: 4,
      currency: "PLN",
    },
    {
      id: "ac3859e5-ad7f-4f7b-964b-df70eec7ac5c",
      name: "Oszczędności",
      type: "asset",
      isVisible: true,
      order: 5,
      currency: "USD",
    },
    {
      id: "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0",
      name: "Karta kredytowa",
      type: "liability",
      isVisible: true,
      order: 6,
      currency: "PLN",
    },
    {
      id: "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46",
      name: "Kredyt hipoteczny",
      type: "liability",
      isVisible: true,
      order: 7,
      currency: "PLN",
    },
    {
      id: "089dd540-244d-4978-a786-27990e0fdcc4",
      name: "Pożyczka od brata",
      type: "liability",
      isVisible: false,
      order: 8,
      currency: "PLN",
    },
  ],
  entries: [
    {
      id: "2a321958-c051-4f8a-bf1b-076c5bb1fabb",
      date: "2022-09-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 7999.12,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 164005.34,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1511.55,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 14423.98,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
        "ac3859e5-ad7f-4f7b-964b-df70eec7ac5c": 1400,
      },
      exchangeRateDate: "2022-09-01T00:00:00",
    },
    {
      id: "218771fa-b239-4d89-9d8e-096fcf24edb7",
      date: "2021-05-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 23589.1,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 193154.67,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1381.38,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 9008.91,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-04-30T00:00:00",
    },
    {
      id: "83bbdd81-2092-48da-9595-0a96bd8f0c01",
      date: "2021-07-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 14106.66,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 182222.43,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1341.9,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 9612.33,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-07-01T00:00:00",
    },
    {
      id: "c37a96bc-049a-4adb-aae4-0b7d02bcf868",
      date: "2022-03-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 14000.99,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 178340.1,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1450.41,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 11298.12,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
      },
      exchangeRateDate: "2022-03-01T00:00:00",
    },
    {
      id: "1a9258b2-8062-4d8c-9e43-14a130332c29",
      date: "2022-01-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 12452.9,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 179401.17,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1423.12,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 11402.94,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
      },
      exchangeRateDate: "2021-12-31T00:00:00",
    },
    {
      id: "0e47a979-daab-48ea-bd2e-390106151449",
      date: "2022-05-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 18811.29,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 176751.22,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1212.09,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 11322.3,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
        "ac3859e5-ad7f-4f7b-964b-df70eec7ac5c": 1000,
      },
      exchangeRateDate: "2022-04-29T00:00:00",
    },
    {
      id: "ac31fe38-0703-42e4-9839-42ffc075ca63",
      date: "2020-09-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 22500.12,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 196837.41,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1109.28,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 7910.3,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-09-01T00:00:00",
    },
    {
      id: "05e2e146-7b68-4c91-b1aa-525c7fdd525c",
      date: "2022-07-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 19133.01,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 175211.02,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1611.11,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 12610.23,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
        "ac3859e5-ad7f-4f7b-964b-df70eec7ac5c": 1200,
      },
      exchangeRateDate: "2022-07-01T00:00:00",
    },
    {
      id: "36d44e05-9e85-4745-9dbf-5bd035bdf659",
      date: "2022-02-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 13300.21,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 178830.25,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1602.22,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 10898.1,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
      },
      exchangeRateDate: "2022-02-01T00:00:00",
    },
    {
      id: "6a1e8a4a-cd40-4eda-90b5-5ca69e2dfc2e",
      date: "2020-06-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 29120.2,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 198199.58,
        "089dd540-244d-4978-a786-27990e0fdcc4": 6000,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 919.06,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 7400.12,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-06-01T00:00:00",
    },
    {
      id: "7765b2f1-f4d1-4132-8192-693da9d0ab9e",
      date: "2021-03-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 24567.89,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 194082.27,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 980.54,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 8808.88,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-03-01T00:00:00",
    },
    {
      id: "45d9b43f-5085-45a9-b744-6c15d3eccf1d",
      date: "2022-10-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 9200.9,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 163487.11,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1910.82,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 16231.01,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
        "ac3859e5-ad7f-4f7b-964b-df70eec7ac5c": 1600,
      },
      exchangeRateDate: "2022-09-30T00:00:00",
    },
    {
      id: "667fef3b-76b5-440c-8462-71c9d1dbf2f0",
      date: "2020-11-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 23512.98,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 195923.61,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1394.8,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 8100.32,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-10-30T00:00:00",
    },
    {
      id: "6ec83296-3d6b-40d8-b209-844be732861c",
      date: "2020-07-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 23459.19,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 197746.66,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1200.39,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 7298.99,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-07-01T00:00:00",
    },
    {
      id: "c8d1df68-052c-4f9b-9ec1-84578ef08867",
      date: "2021-01-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 24100.38,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 195005.24,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1078.95,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 8412.36,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-12-31T00:00:00",
    },
    {
      id: "ba59f44d-418c-443d-9f4a-8836cb6af36d",
      date: "2021-12-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 13800.1,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 179872.55,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1523.33,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 11231.12,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-12-01T00:00:00",
    },
    {
      id: "0fa52f65-2c56-4af6-b7ce-99fbacb5c39b",
      date: "2021-06-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 13811.77,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 182689.13,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1230.73,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 9234.12,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-06-01T00:00:00",
    },
    {
      id: "d0d4f1a1-71b9-4b83-9b92-9de3d5f84843",
      date: "2020-05-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 29512.9,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 198651.37,
        "089dd540-244d-4978-a786-27990e0fdcc4": 7000,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 750.4,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 7100.32,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-04-30T00:00:00",
    },
    {
      id: "a06fdb1c-b2a7-45eb-83a6-a9711f31d22e",
      date: "2022-08-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 18498.11,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 174635.35,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1923.32,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 14120.19,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
        "ac3859e5-ad7f-4f7b-964b-df70eec7ac5c": 1300,
      },
      exchangeRateDate: "2022-08-01T00:00:00",
    },
    {
      id: "c109d396-9067-43dc-82bb-aba19a31a96b",
      date: "2022-06-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 17222.11,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 175921.49,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1412.09,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 11422.4,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
        "ac3859e5-ad7f-4f7b-964b-df70eec7ac5c": 1100,
      },
      exchangeRateDate: "2022-06-01T00:00:00",
    },
    {
      id: "d3c1d6c9-9699-4930-882b-ad4144898669",
      date: "2020-08-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 22908.51,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 197292.6,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 2402.84,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 7698.09,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-07-31T00:00:00",
    },
    {
      id: "0cb0211d-373a-4b57-a6b0-b358c255dbe7",
      date: "2020-10-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 23123.89,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 196381.08,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 987.48,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 7778.98,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-10-01T00:00:00",
    },
    {
      id: "477423be-3924-46e7-94f1-b3724e1e3fe3",
      date: "2021-04-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 23400,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 193619.05,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1500.39,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 8501.23,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-04-01T00:00:00",
    },
    {
      id: "e2e2742e-d9ed-4dda-b9b0-b38ae3ebdc7d",
      date: "2020-12-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 23800.04,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 195465,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 890.35,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 8033.98,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-12-01T00:00:00",
    },
    {
      id: "7f501df9-f050-4ca5-a305-bb1669753583",
      date: "2020-03-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 28100.13,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 199551.58,
        "089dd540-244d-4978-a786-27990e0fdcc4": 9000,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1039.71,
        "9f28846b-f34d-4766-8a72-383254c54751": 20000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 10320.12,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-02-28T00:00:00",
    },
    {
      id: "579a9874-f658-43d7-9f5c-bbf919c6002b",
      date: "2020-01-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 59187.9,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1103.59,
        "9f28846b-f34d-4766-8a72-383254c54751": 20000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 10500.23,
      },
      exchangeRateDate: "2019-12-31T00:00:00",
    },
    {
      id: "a8fb3803-556f-40e7-a165-c68ff0026a7b",
      date: "2022-04-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 14811.29,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 177855.22,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1612.09,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 10322.3,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
      },
      exchangeRateDate: "2022-04-01T00:00:00",
    },
    {
      id: "134c2714-e50a-4f17-a7b0-d36b81070f9a",
      date: "2020-02-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 28471.2,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 200000,
        "089dd540-244d-4978-a786-27990e0fdcc4": 10000,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 912.63,
        "9f28846b-f34d-4766-8a72-383254c54751": 20000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 10432.98,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-01-31T00:00:00",
    },
    {
      id: "9bdd1d96-41c6-43d5-bf86-d67ba14c1f54",
      date: "2021-08-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 15009.11,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 181754.56,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1410.07,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 10333.43,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-07-30T00:00:00",
    },
    {
      id: "8aefe1d7-7e44-4be6-b087-ddc02cd7080f",
      date: "2021-11-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 13123.23,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 180343.93,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1434.44,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 10543.44,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-10-29T00:00:00",
    },
    {
      id: "34dab90e-4485-4409-9102-e67babc606e8",
      date: "2021-09-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 15201.22,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 181285.52,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1033.34,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 9988.77,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-09-01T00:00:00",
    },
    {
      id: "0af108d3-9d82-4cfe-bd52-e6834b4a6ae9",
      date: "2021-02-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 24080.9,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 194544.33,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 889.4,
        "9f28846b-f34d-4766-8a72-383254c54751": 18000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 8723.09,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 245000,
      },
      exchangeRateDate: "2021-02-01T00:00:00",
    },
    {
      id: "86c70fa1-ece4-45b3-a624-e9af8aa49220",
      date: "2020-04-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 29310.49,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 199102.04,
        "089dd540-244d-4978-a786-27990e0fdcc4": 8000,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1734.9,
        "9f28846b-f34d-4766-8a72-383254c54751": 20000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 6989.91,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 240000,
      },
      exchangeRateDate: "2020-04-01T00:00:00",
    },
    {
      id: "ebcc6a1e-3072-49bb-97dd-ea5e252e50b6",
      date: "2021-10-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 15010.1,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 180815.31,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1698.99,
        "9f28846b-f34d-4766-8a72-383254c54751": 16000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 10211.23,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 250000,
      },
      exchangeRateDate: "2021-10-01T00:00:00",
    },
    {
      id: "96394ff4-d72c-475d-8026-f4f7e777c105",
      date: "2022-11-01",
      partValues: {
        "b4e19420-851c-4e44-b40d-09fec99eb231": 10039.12,
        "7ceb3c36-2da4-4ac4-ab4f-200dc39baf46": 162812.92,
        "9c5bfd03-d5df-4fc8-b325-2eaafeee5ee0": 1298.37,
        "9f28846b-f34d-4766-8a72-383254c54751": 14000,
        "52c952c2-0cff-4cb9-bbe6-887ed052ee39": 16320.28,
        "82ce2911-5b82-491c-a75c-af82cdf3abe5": 255000,
        "ac3859e5-ad7f-4f7b-964b-df70eec7ac5c": 1700,
      },
      exchangeRateDate: "2022-10-07T00:00:00",
    },
  ],
  goals: [
    {
      id: "c2d87f0b-0765-4bbe-9ad8-892229909169",
      parts: ["7ceb3c36-2da4-4ac4-ab4f-200dc39baf46"],
      deadline: "2030-01-01T00:00:00",
      value: 0,
      returnRate: 0,
      name: "Spłata hipoteki",
    },
    {
      id: "1762ad6a-872f-42a3-9877-8afd2f4fca4d",
      parts: ["52c952c2-0cff-4cb9-bbe6-887ed052ee39"],
      deadline: "2050-01-01T00:00:00",
      value: 1000000,
      returnRate: 5,
      name: "Emerytura",
    },
  ],
  rates: [
    {
      currency: "USD",
      date: "2019-12-31T00:00:00",
      rate: 3.7977,
    },
    {
      currency: "USD",
      date: "2020-01-31T00:00:00",
      rate: 3.8999,
    },
    {
      currency: "USD",
      date: "2020-02-28T00:00:00",
      rate: 3.9255,
    },
    {
      currency: "USD",
      date: "2020-04-01T00:00:00",
      rate: 4.1701,
    },
    {
      currency: "USD",
      date: "2020-04-30T00:00:00",
      rate: 4.1729,
    },
    {
      currency: "USD",
      date: "2020-06-01T00:00:00",
      rate: 3.968,
    },
    {
      currency: "USD",
      date: "2020-07-01T00:00:00",
      rate: 3.9591,
    },
    {
      currency: "USD",
      date: "2020-07-31T00:00:00",
      rate: 3.7166,
    },
    {
      currency: "USD",
      date: "2020-09-01T00:00:00",
      rate: 3.6707,
    },
    {
      currency: "USD",
      date: "2020-10-01T00:00:00",
      rate: 3.8513,
    },
    {
      currency: "USD",
      date: "2020-10-30T00:00:00",
      rate: 3.9563,
    },
    {
      currency: "USD",
      date: "2020-12-01T00:00:00",
      rate: 3.7367,
    },
    {
      currency: "USD",
      date: "2020-12-31T00:00:00",
      rate: 3.7584,
    },
    {
      currency: "USD",
      date: "2021-02-01T00:00:00",
      rate: 3.7323,
    },
    {
      currency: "USD",
      date: "2021-03-01T00:00:00",
      rate: 3.7572,
    },
    {
      currency: "USD",
      date: "2021-04-01T00:00:00",
      rate: 3.9428,
    },
    {
      currency: "USD",
      date: "2021-04-30T00:00:00",
      rate: 3.7746,
    },
    {
      currency: "USD",
      date: "2021-06-01T00:00:00",
      rate: 3.659,
    },
    {
      currency: "USD",
      date: "2021-07-01T00:00:00",
      rate: 3.8149,
    },
    {
      currency: "USD",
      date: "2021-07-30T00:00:00",
      rate: 3.841,
    },
    {
      currency: "USD",
      date: "2021-09-01T00:00:00",
      rate: 3.8238,
    },
    {
      currency: "USD",
      date: "2021-10-01T00:00:00",
      rate: 3.9678,
    },
    {
      currency: "USD",
      date: "2021-10-29T00:00:00",
      rate: 3.9664,
    },
    {
      currency: "USD",
      date: "2021-12-01T00:00:00",
      rate: 4.1051,
    },
    {
      currency: "USD",
      date: "2021-12-31T00:00:00",
      rate: 4.06,
    },
    {
      currency: "USD",
      date: "2022-02-01T00:00:00",
      rate: 4.0689,
    },
    {
      currency: "USD",
      date: "2022-03-01T00:00:00",
      rate: 4.2193,
    },
    {
      currency: "USD",
      date: "2022-04-01T00:00:00",
      rate: 4.1978,
    },
    {
      currency: "USD",
      date: "2022-04-29T00:00:00",
      rate: 4.4072,
    },
    {
      currency: "USD",
      date: "2022-06-01T00:00:00",
      rate: 4.2817,
    },
    {
      currency: "USD",
      date: "2022-07-01T00:00:00",
      rate: 4.5106,
    },
    {
      currency: "USD",
      date: "2022-08-01T00:00:00",
      rate: 4.629,
    },
    {
      currency: "USD",
      date: "2022-09-01T00:00:00",
      rate: 4.6959,
    },
    {
      currency: "USD",
      date: "2022-09-30T00:00:00",
      rate: 4.9533,
    },
    {
      currency: "USD",
      date: "2022-10-07T00:00:00",
      rate: 4.9588,
    },
  ],
};
