export const propertiesMock = [
  {
    id: "42f8e10b-cd67-4ca5-8e65-1eb5b9195b35",
    name: "Przykład kosztów",
    isActive: true,
  },
  {
    id: "6a672a64-835f-4c5b-88ce-a10260f5569a",
    name: "Przykład wynajmu",
    isActive: true,
  },
];
