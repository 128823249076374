export const notificationsMock = [
  {
    id: "8a20f7c5-aac1-4930-9c0b-a5c95b09bc16",
    type: "networthdata",
    message: "Podaj aktualne wartości Twojego majątku",
    url: "/networth/data",
    isRead: false,
    date: "2022-02-01T00:00:00",
  },
  {
    id: "8a3db974-00cd-4738-8b76-ad0810b3f6cd",
    type: "maintenance",
    message: "dnia miesiąca w godzinach 21:00-21:15",
    url: null,
    isRead: true,
    date: "2022-02-01T00:00:00",
  },
];
